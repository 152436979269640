<template>
  <div class="main-container">
      <Header> </Header>
        
      <div class="portfolio-section">
        <div class="portfolio-container">
          <h2>Portfolio</h2>
          <div class="portfolio-items">
            <div v-for="(item, index) in portfolioData" :key="index" :class="portfolio-item">
                <PortfolioItem :id="index" :data="item" @showModal="showModal"/>
            </div>
          </div>
        </div> 
       </div>
       <Modal :data="modalPortfolioData" v-if="isModalVisible" @close="hideModal"></Modal>
      <Footer></Footer>
     
  </div>
</template>

<script>
import Header from './Header.vue';
import Footer from './Footer.vue';
import Modal from './Modal.vue'
import PortfolioItem from './PortfolioItem.vue';
import portfolioData from "./../resources/portfolio.json"

export default {
    name: "Portfolio",
    components: { Header, Footer, PortfolioItem, Modal },
    metaInfo: {
      title: 'Portfolio',
      titleTemplate: 'M Tower | Građevinska firma | %s'
    },
    data : () => {
      return {
        isModalVisible : false,
        modalPortfolioData: {
          portfolioData: {},
          routeGallery: ""
        },
        portfolioData : portfolioData,
      }
    },

    methods: {
      showModal: function(portfolioItemData, galleryName) {
        this.isModalVisible = true
        this.modalPortfolioData = {portfolioData: portfolioItemData, routeGallery : galleryName }
      },
      hideModal: function() {
        this.isModalVisible = false;
        this.modalPortfolioData = {portfolioData: {}, routeGallery: ""}
      }
    }
}
</script>
