<template>
 <div class="portfolio-item">
      <img :src="require('../' + data.thumbImageUrl)" />
      <br/>
      <p>{{ data.name }}</p>
      <ul>
        <li v-if="canItShow('apartments')">
          <a :style="{ cursor: 'pointer'}" @click.prevent="showModal('apartments')">{{ $t('apartment-types') }}</a>
        </li>
        <li v-if="canItShow('misc')">
          <a :style="{ cursor: 'pointer'}" @click.prevent="showModal('misc')">{{ $t('gallery') }}</a>
        </li>
        <li v-if="canItShow('news')">
          <a :style="{ cursor: 'pointer'}" @click.prevent="showModal('news')">{{ $t('news') }}</a>
        </li>
        <li v-if="data.tourUrl">
          <a :href="data.tourUrl" target="_blank">{{ $t('tour') }}</a>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'PortfolioItem',
  props: {
    id: Number,
    data: Object
  },
  methods: {
    showModal : function(galleryName) {
        this.$emit("showModal", this.data, galleryName);
    },
    canItShow(galleryName) {
        return this.data.galleries[galleryName].length > 0;
    }
  }
}
</script>
