import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Home from './components/Home.vue'
import Portfolio from './components/Portfolio.vue'
import Meta from 'vue-meta'

import "@/assets/style.css"
import i18n from './i18n'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Meta, {refreshOnceOnNavigation: true})

const routes = [
  { path: '/', component: Home },
  { path: '/portfolio', component: Portfolio }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

new Vue({
  render: h => h(App),
  i18n,
  router: router
}).$mount('#app')


