<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    meta: [
      { name: 'description', content: 'M Tower je gradjevinska firma koja se bavi izgradnjom stambeno poslovnih objekata od 2020. godine. Ponosni smo pioniri u ovoj oblasti, svoj rast i razvoj gradimo kroz uspešne projekte, isključivo koračajući u paru sa kvalitetom, preciznošću i jasnom vizijom. Sa nama do vašeg doma' },
      { name: 'keywords', content: 'građevina, zgrade, novogradnja, Kragujevac, Beograd, Srbija, investitor, dom, stan, apartman, kuća, lokal, parking' }
    ]
  }
  }
</script>

<style>
</style>
