<template>
   <div class="main-container">
       <div class="landing-container">
          <Header></Header>

           <div class="landing-content section-container">
            <div class="caption">
              <div class="rectangle"></div>
              <h2>{{ $t('landing-caption') }}</h2>
            </div>
          </div>
       </div>
        <div class="section-container section-vision">
          <div class="one">
            <h2 class="header-yellow">{{ $t('our-vision') }}</h2>
            <p>{{ $t('vision') }}</p>
          </div>
          <div class="two"><img src="../assets/img/vision.png" /></div>
        </div>
     
        <div class="section-container about-us">
          <div class="about-us-content">
            <h2 class="header-yellow">{{ $t('about.header') }}</h2>
            <p>
             {{ $t('about.first') }}
            </p>
            <p>
            {{ $t('about.second') }}
            </p>
            <p>{{ $t('landing-caption') }}</p>
            <p>{{ $t('about.third') }}</p>
          </div>
        </div>

  
        <div class="section-container section-vision">
          <div class="one">
            <h2 class="header-yellow">{{ $t('vision-section.space') }}</h2>
            <p>{{ $t('vision-section.customers') }}
            </p>
            <router-link class="portfolio-btn" to="/portfolio" tag="button">{{ $t('vision-section.portfolio') }}</router-link>
          </div>
          <div class="two"><img src="../assets/img/portfoilo.png" /></div>
        </div>
  
        <div class="news">
          <div class="section-container news-content">
            <h2>{{ $t('news-section') }}</h2>
          </div>
        </div>

        <div class="section-container image-section">
          <div class="column">
            <img src="../assets/img/image-1.png" />
            <img src="../assets/img/image-3.png" />
          </div>
          <div class="column">
            <img src="../assets/img/image-2.png" />
            <img src="../assets/img/image-4.png" />
          </div>

        </div>
      <Footer></Footer>
</div>

</template>

<script>
import Header from "./Header.vue"
import Footer from "./Footer.vue"

export default {
  name: 'Home',
  components: {Header, Footer},
  metaInfo: {
      title: 'Početna',
      titleTemplate: 'M Tower | Građevinska firma | %s'
   }
}
</script>