<template>
<div class="modal-page">
 <div class="modal-container">
        <header class="modal-header">
          <p>{{ data.portfolioData.name }}</p>
          <a :style="{ cursor: 'pointer', color: '#FFFFFF'}" @click.prevent="close">X</a>
        </header>
        <div class="modal-content">
          <div class="navigation">
            <nav>
              <ul>
                <li v-if="canItShow('apartments')">
                  <a :style="{ cursor: 'pointer'}" @click.prevent="showGallery('apartments')">{{ $t('apartment-types') }}</a>
                </li>
                <li v-if="canItShow('misc')">
                  <a :style="{ cursor: 'pointer'}" @click.prevent="showGallery('misc')">{{ $t('gallery') }}</a>
                </li>
                <li v-if="canItShow('news')">
                  <a :style="{ cursor: 'pointer'}" @click.prevent="showGallery('news')">{{ $t('news') }}</a>
                </li>
              </ul>
          </nav>
             <a v-if="data.portfolioData.tourUrl" :href="data.portfolioData.tourUrl" target="_blank"><img src="../assets/img/3d.png"></a>
           </div>
          <ModalGallery v-bind:data="data.portfolioData.galleries[galleryName]"></ModalGallery>
        </div>
    </div>
</div>

</template>

<script>
import ModalGallery from './ModalGallery.vue';
export default {
    name: "Modal",
    components: { ModalGallery },
    props: {
        data: Object,
    },
    data : () => {
      return {
        galleryName : '',
      }
    },
    created : function() {
      this.galleryName = this.data.routeGallery
    },
    methods: {
      close() {
        this.$emit('close');
      },
      
      showGallery(galleryName) {
        if (this.data.portfolioData.galleries[galleryName].length > 0) {
          this.galleryName = galleryName
        }        
      },
      canItShow(galleryName) {
        return this.data.portfolioData.galleries[galleryName].length > 0;
      }
    }
   
}
</script>