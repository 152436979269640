<template>
    <header class="header">
      <div class="logo"><img width="230px" src="../assets/img/logo.png" /></div>
      <Navigation />
    </header>
</template>

<script>
import Navigation from './Navigation.vue'

export default {
  name: 'Header',
  components: { Navigation }
}
</script>