<template>
<div class="modal-gallery">
    <div class="gallery-item-nav">
      <div @click.prevent="previous" class="prev-btn"></div>
        <p>Prethodna</p>
      </div>
       <div class="image-content">
          <img :key="currentIndex" :src="require('../' + currentImage.url)" >
       </div>
    
    <div class="gallery-item-nav">
      <div @click.prevent="next" class="next-btn"></div>
      <p>Sledeća</p>
    </div>
</div>

</template>

<script>
export default {
    name: "ModalGallery",
    props: {
        data: Array
    },
    data : () => {
      return {
        currentIndex : 0,
        currentImage : {url: '', caption : ''}
      }
    },
    created() {
      this.currentImage = this.data[this.currentIndex]
    },
    methods: {
      next() {
        if (this.currentIndex < this.data.length - 1) {
          ++this.currentIndex;
          this.currentImage = this.data[this.currentIndex]
        }
      },
      previous() {
        if (this.currentIndex > 0) {
          --this.currentIndex;
          this.currentImage = this.data[this.currentIndex]
        }
      }
    },
    watch : {
      data : function(newVal, oldVal) {
        if (oldVal != newVal) {
          this.currentIndex = 0
          this.currentImage = newVal[this.currentIndex];
        }
    }
  },
  } 

</script>