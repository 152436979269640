<template>
      <nav>
        <ul>
          <li>
            <router-link to="/" tag="a">{{ $t('home') }}</router-link>
          </li>
          <li>
            <router-link to="/portfolio" tag="a">{{ $t('portfolio') }}</router-link>
          </li>
          <li>
            <a href="#contact">{{ $t('contact') }}</a>
          </li>
          <li>
            <LocaleChanger></LocaleChanger>
          </li>
        </ul>
      </nav>
</template>

<script>
import LocaleChanger from "./LocaleChanger.vue"

export default {
  name: 'Navigation',
  components: {LocaleChanger}
}
</script>