<template>
 <footer id="contact" class="section-container footer-container">
  <h2>{{ $t('contact') }}</h2>
  <h3>M Tower</h3>
  <div class="contact-container">
    <div class="contact-row">
      <div class="info">
        <a href="mailto:info@mtower.org">info@mtower.org</a><br>
        <a href="tel:+38166311991">+38166311991</a>
      </div>
      <div>
          <Navigation></Navigation>
      </div>
    </div>  
    <div class="social">
      <ul>
        <li>
          <a href="https://www.instagram.com/mtower__" target="_blank">
            <button class="icon ion-logo-instagram"></button>
          </a>
        </li>
        <li>
          <a href="" target="_blank">
            <button class="icon ion-logo-facebook"></button>
          </a>
        </li>
      </ul>
      </div>

  </div>    
    <div class="copyright">
      <p>Copyright © 2022 M Tower. {{ $t('copyrights')}}</p>
      <p>{{ $t('designed')}}</p>
    </div>  
  </footer>
</template>

<script>
import Navigation from './Navigation.vue'
export default {
  name: 'Footer',
  components: {Navigation}
}
</script>